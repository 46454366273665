import React from 'react'
import { injectIntl, Link } from 'gatsby-plugin-intl'
import Layout from '../components/Layout'
import SEO from '../components/SEO'

const NoBlogList = ({ intl }) => {
  return (
    <Layout>
      <SEO title="Blog List" />
      <section className="fullscreen">
        <div className="container v-align-transform">
          <div className="row">
            <div className="col-sm-10 col-sm-offset-1">
              <div className="text-center">
                <i className="ti-receipt icon icon-lg mb24 mb-xs-0" />
                <h1 className="large">
                  {intl.formatMessage({ id: 'noblogs.header' })}
                </h1>
                <p>{intl.formatMessage({ id: 'noblogs.description' })}</p>
                <Link className="btn" to="/">
                  {intl.formatMessage({ id: 'noblogs.back_btn' })}
                </Link>
                <Link className="btn" to="/contact">
                  {intl.formatMessage({ id: 'noblogs.contact_btn' })}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}
export default injectIntl(NoBlogList)
